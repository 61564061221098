import { createSlice } from '@reduxjs/toolkit';
// project imports
import axiosServices, { axiosReportService } from 'utils/axios';
import { SIZE } from '../constant';
import { dispatch } from 'store';

export const initialState = {
    savedTemplates: [],
    sharedTemplates: [],
    standardTemplates: [],
    saveTemplateLoaded: false,
    saveTemplateLoadingStart: false,
    standardDataLoaded: false,
    savedDataLoaded: false,
    isDeleteInProgress: false,
    isDeleteCompleted: false,
    tags: [],
    dataMap: [],
    updatedDataMap: [],
    shareTemplateLoadingStart: false,
    shareTemplateLoaded: false,
    shareTemplateUsers: [],
    shareListLoaded: false,
    isRoleUpdated: false,
    ownerNotValid: false,
    isUpdateStart: false,
    changeOwnerStart: false,
    templatesForScheduling: [],
    templatesForSchedulingLoading: false,
    templatesForSchedulingError: null,
    hasMoreSavedTemplateData: true,
    hasMoreSharedTemplateData: true,
    isDeleteConfirmationrequire: false,
    deleteTemplateError: {},
    isReassignComplete: false,
    deleteAccessDone: false,
    ownerList: [],
    loading: false
};

export const slice = createSlice({
    name: 'templates',
    initialState,
    reducers: {
        markAsFavorite(state, action) {
            if (action.payload.templateType === 'saved') {
                for (let i = 0; i < state.savedTemplates.length; i += 1) {
                    if (state.savedTemplates[i].templateId === action.payload.templateId) {
                        state.savedTemplates[i].isFavorite = !state.savedTemplates[i].isFavorite;
                    }
                }
            } else if (action.payload.templateType === 'shared') {
                for (let i = 0; i < state.sharedTemplates.length; i += 1) {
                    if (state.sharedTemplates[i].templateId === action.payload.templateId) {
                        state.sharedTemplates[i].isFavorite = !state.sharedTemplates[i].isFavorite;
                    }
                }
            }
        },
        getStandardListSuccess(state, action) {
            state.standardTemplates = action.payload;
            state.standardDataLoaded = true;
        },
        getStandardListFailure(state, action) {
            state.standardDataLoaded = true;
        },
        getSavedListSuccess(state, action) {
            if (action.payload.length < SIZE) {
                state.hasMoreSavedTemplateData = false;
            }
            state.savedTemplates = state.savedTemplates.concat(action.payload);
            state.savedDataLoaded = true;
        },
        getSavedListFailure(state, action) {
            state.savedDataLoaded = true;
        },

        getSharedListSuccess(state, action) {
            if (action.payload.length < SIZE) {
                state.hasMoreSharedTemplateData = false;
            }
            state.sharedTemplates = state.sharedTemplates.concat(action.payload);
            state.shareListLoaded = true;
        },

        getSharedListFailure(state, action) {
            state.shareListLoaded = true;
        },
        removeTagFromTemplateSuccess(state, action) {
            state.saveTemplateLoadingStart = false;
            state.isUpdateStart = false;

            for (let i = 0; i < state.savedTemplates.length; i += 1) {
                if (state.savedTemplates[i].templateId === action.payload.templateId) {
                    state.savedTemplates[i].tags = [
                        ...state.savedTemplates[i].tags.filter((templateTag) => {
                            return !action.payload.tagIds.includes(templateTag.tagId);
                        })
                    ];
                    break;
                }
            }

            for (let i = 0; i < state.sharedTemplates.length; i += 1) {
                if (state.sharedTemplates[i].templateId === action.payload.templateId) {
                    state.sharedTemplates[i].tags = [
                        ...state.sharedTemplates[i].tags.filter((templateTag) => {
                            return !action.payload.tagIds.includes(templateTag.tagId);
                        })
                    ];
                    break;
                }
            }
            state.loading = false;
        },

        createSaveTemplateSuccess(state, action) {
            state.saveTemplateLoadingStart = false;
            state.saveTemplateLoaded = !action.payload?.notCloseModal;
            state.isUpdateStart = false;

            // update title, description, tags intp standardTemplates
            if (action.payload?.isRename) {
                if (action.payload?.templateType === 'saved') {
                    for (let i = 0; i < state.savedTemplates.length; i += 1) {
                        if (state.savedTemplates[i].templateId === action.payload.data.templateId) {
                            state.savedTemplates[i].templateName = action.payload.data.templateName;
                            state.savedTemplates[i].templateDescription = action.payload.data.templateDescription;
                            state.savedTemplates[i].tags = [
                                ...action.payload.data.templateTags
                                    .map((templateTag) => {
                                        return state.tags.find(({ value }) => value === templateTag.tagId);
                                    })
                                    .map((data) => ({ tagId: data.value, tagName: data.label }))
                            ];
                            break;
                        }
                    }
                } else if (action.payload?.templateType === 'shared') {
                    for (let i = 0; i < state.sharedTemplates.length; i += 1) {
                        if (state.sharedTemplates[i].templateId === action.payload.data.templateId) {
                            state.sharedTemplates[i].templateName = action.payload.data.templateName;
                            state.sharedTemplates[i].templateDescription = action.payload.data.templateDescription;
                            state.sharedTemplates[i].tags = [
                                ...action.payload.data.templateTags
                                    .map((templateTag) => {
                                        return state.tags.find(({ value }) => value === templateTag.tagId);
                                    })
                                    .map((data) => ({ tagId: data.value, tagName: data.label }))
                            ];
                            break;
                        }
                    }
                }
            }
            state.loading = false;
        },
        createShareTemplateSuccess(state, action) {
            state.shareTemplateLoadingStart = false;
            state.shareTemplateLoaded = true;
            if (action.payload && action.payload.templateType) {
                if (action.payload.templateType === 'saved') {
                    for (let i = 0; i < state.savedTemplates.length; i += 1) {
                        if (state.savedTemplates[i].templateId === action.payload.templateId) {
                            if (action.payload.isOwnerChange) {
                                const newList = state.savedTemplates.filter((item) => action.payload.templateId !== item.templateId);
                                state.savedTemplates = newList;
                            } else {
                                state.savedTemplates[i].isShared = true;
                            }
                        }
                    }
                }
                if (action.payload.templateType === 'shared') {
                    for (let i = 0; i < state.sharedTemplates.length; i += 1) {
                        if (state.sharedTemplates[i].templateId === action.payload.templateId) {
                            state.sharedTemplates[i].isShared = true;
                        }
                    }
                }
            }
        },
        createDeleteAccessSuccess(state, action) {
            state.deleteAccessDone = true;
        },

        createSaveTemplateStart(state, action) {
            state.saveTemplateLoadingStart = true;
            state.isUpdateStart = true;
            state.loading = true;
        },
        createUpdateOwnerSuccess(state, action) {
            state.changeOwnerStart = false;
            state.saveTemplateLoaded = true;
            state.isReassignComplete = true;
            if (action.payload?.ownerUpdated) {
                const newList = state.savedTemplates.filter((item) => action.payload.templateId !== item.templateId);
                state.savedTemplates = newList;
            }
        },
        createResetTemplateLoaded(state, action) {
            state.saveTemplateLoaded = false;
            state.saveTemplateLoadingStart = false;
        },
        createResetShareTemplateData(state, action) {
            state.shareTemplateUsers = [];
            state.shareTemplateLoaded = false;
            state.shareTemplateLoadingStart = false;
            state.updatedDataMap = [];
            state.dataMap = [];
            state.shareTemplateUsers = [];
            state.isRoleUpdated = false;
            state.deleteAccessDone = false;
        },
        resetOwnerValidation(state, action) {
            state.ownerNotValid = false;
            state.changeOwnerStart = false;
        },

        updateDeleteTemplateStart(state, action) {
            state.isDeleteInProgress = action.payload;
        },
        updateDeleteTemplateComplete(state, action) {
            state.isDeleteCompleted = action.payload;
            state.isReassignComplete = false;
        },
        updateReassignComplete(state, action) {
            state.isReassignComplete = action.payload;
        },
        createDeleteTemplate(state, action) {
            if (action.payload.isFromShared) {
                const newList = state.sharedTemplates.filter((item) => !action.payload.templateIds.find((rm) => item.templateId === rm));
                state.sharedTemplates = newList;
            } else {
                const newList = state.savedTemplates.filter((item) => !action.payload.templateIds.find((rm) => item.templateId === rm));
                state.savedTemplates = newList;
            }
        },
        getTagsListSuccess(state, action) {
            const data = [];
            action.payload.forEach((tag) => {
                const item = {};
                item.label = tag.tagName;
                item.value = tag.tagId;
                data.push(item);
            });
            state.tags = data;
        },
        validEmailAddress(state, action) {
            const data = {
                key: action.payload.email,
                value: action.payload.data
            };
            state.dataMap.push(data);
        },

        changeOwnerStart(state, action) {
            state.changeOwnerStart = true;
        },

        notValidEmailAddress(state, action) {
            const data = {
                key: action.payload,
                value: null
            };
            state.dataMap.push(data);
            state.ownerNotValid = true;
            state.changeOwnerStart = false;
        },

        createShareTemplateStart(state, action) {
            state.shareTemplateLoadingStart = true;
        },

        setShareTemplateUsers(state, action) {
            const result = [];
            state.shareTemplateUsers = action.payload;
            state.updatedDataMap = action.payload;
        },
        updateShareTemplateUsers(state, action) {
            const newList = state.updatedDataMap.filter((item) => action.payload !== item.shareeLoginName);
            state.shareTemplateUsers = newList;
            state.updatedDataMap = newList;
        },

        setShareTemplateFailure(state, action) {
            // state.shareDataLoaded = true;
        },
        updateExistingUserRole(state, action) {
            for (let i = 0; i < state.updatedDataMap.length; i += 1) {
                if (state.updatedDataMap[i].shareeLoginName === action.payload.email) {
                    state.updatedDataMap[i].shareeRole = action.payload.selectedOption;
                    break;
                }
            }

            if (JSON.stringify(state.updatedDataMap) === JSON.stringify(state.shareTemplateUsers)) {
                state.isRoleUpdated = false;
            } else {
                state.isRoleUpdated = true;
            }
        },

        removeExistingUser(state, action) {
            const newList = state.updatedDataMap.filter((item) => !action.payload.email === item.loginName);
            state.updatedDataMap = newList;
            state.isRoleUpdated = true;
        },
        fetchTemplatesForSchedulingRequest(state) {
            state.templatesForSchedulingLoading = true;
            state.templatesForSchedulingError = null;
            state.templatesForScheduling = [];
        },
        fetchTemplatesForSchedulingSuccess(state, action) {
            state.templatesForSchedulingLoading = false;
            state.templatesForScheduling = action.payload;
        },
        fetchTemplatesForSchedulingError(state, action) {
            state.templatesForSchedulingLoading = false;
            state.templatesForSchedulingError = action.payload;
        },
        fetchSavedTemplateRequest(state, action) {
            state.savedDataLoaded = false;
            if (action.payload) {
                state.savedTemplates = [];
                state.hasMoreSavedTemplateData = true;
            }
        },
        // fetchSharedTemplateRequest(state, action) {
        //     state.savedDataLoaded = false;
        //     if (action.payload) {
        //         state.sharedTemplates = [];
        //         state.hasMoreSavedTemplateData = true;
        //     }
        // },
        fetchSharedTemplateRequest(state, action) {
            state.shareListLoaded = false;
            if (action.payload) {
                state.sharedTemplates = [];
                state.hasMoreSharedTemplateData = true;
            }
        },
        setOwnerList(state, action) {
            state.ownerList = action.payload;
        },

        updateDeleteConfirmationrequire(state, action) {
            state.isDeleteConfirmationRequire = action.payload.isRequire;
            state.deleteTemplateError = action.payload.error;
        },
        setDeleteConfirmationRequire(state, action) {
            state.isDeleteConfirmationRequire = false;
        }
    }
});

export default slice.reducer;

export function fetchTemplatesForScheduling(enqueueSnackbar) {
    return async (dispatch) => {
        dispatch(slice.actions.fetchTemplatesForSchedulingRequest());
        // Call API to get the list of dashboards for the logged in user - dashId, dashName
        try {
            const allResponse = await Promise.all([
                axiosReportService.get(`/templates?DefaultTemplates=true`),
                axiosReportService.get(`/templates/share`),
                axiosReportService.get(`/templates?DefaultTemplates=false`)
            ]);

            let allData = [];

            allResponse.forEach(({ data }) => {
                if (data.data) {
                    allData = [...allData, ...data.data];
                }
            });

            dispatch(slice.actions.fetchTemplatesForSchedulingSuccess(allData));
        } catch (ex) {
            console.log(ex);
            dispatch(slice.actions.fetchTemplatesForSchedulingError(ex));
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar('Error in getting templates', { variant: 'error' });
            }
        }
    };
}

export function markAsFavorite(enqueueSnackbar, templateId, isFavorite, templateType) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.markAsFavorite({ templateId, templateType }));
            let response;
            if (isFavorite) {
                response = await axiosReportService.delete(`/templates/${templateId}/user/favorite`);
            } else {
                response = await axiosReportService.post(`/templates/${templateId}/user/favorite`);
            }
            if (response?.data?.success) {
                const title = isFavorite ? 'Template removed from Favorite' : 'Template marked favorite';
                enqueueSnackbar(title, { variant: 'info' });
            } else {
                enqueueSnackbar('Failed to mark template as favorite', { variant: 'error' });
            }
            if (response?.status !== 200 || !response?.data?.success) {
                dispatch(slice.actions.markAsFavorite({ templateId, templateType }));
            }
        } catch (ex) {
            dispatch(slice.actions.markAsFavorite({ templateId, templateType }));
            console.log(ex);
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar('Failed to mark template as favorite', { variant: 'error' });
            }
        }
    };
}

export function removeTagFromTemplate(enqueueSnackbar, templateId, tagIds) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createSaveTemplateStart());
            const response = await axiosReportService.delete(`/tags/${templateId}`, { data: tagIds });
            if (response?.data?.success) {
                dispatch(slice.actions.removeTagFromTemplateSuccess({ templateId, tagIds }));
            } else {
                console.log('Error in removing Tag');
            }
        } catch (ex) {
            console.log(ex);
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar('Error in removing Tag', { variant: 'error' });
            }
        }
    };
}

export function getStandardTemplateList(enqueueSnackbar) {
    return async (dispatch) => {
        // Call API to get the list of dashboards for the logged in user - dashId, dashName
        try {
            const response = await axiosReportService.get(`/templates?DefaultTemplates=true`);
            if (response?.data?.success) {
                dispatch(slice.actions.getStandardListSuccess(response.data.data));
            } else {
                dispatch(slice.actions.getStandardListFailure(response?.data.error));
            }
        } catch (ex) {
            console.log(ex);
            dispatch(slice.actions.getStandardListFailure());
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar('Error in getting Standard templates', { variant: 'error' });
            }
        }
    };
}

export function getTags(enqueueSnackbar) {
    return async (dispatch) => {
        // Call API to get the list of dashboards for the logged in user - dashId, dashName
        try {
            const response = await axiosReportService.get(`/tags`);
            if (response?.data?.success) {
                dispatch(slice.actions.getTagsListSuccess(response.data.data));
            } else {
                enqueueSnackbar('Error in getting tags', { variant: 'error' });
            }
        } catch (ex) {
            console.log(ex);
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar('Error in getting tags', { variant: 'error' });
            }
        }
    };
}

export function changeTemplateOwner(enqueueSnackbar, payload, templateId) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createSaveTemplateStart());
            const response = await axiosReportService.patch(`/templates/${templateId}/share`, payload);
            if (response?.data?.success) {
                const ownerUpdated = true;
                dispatch(slice.actions.createUpdateOwnerSuccess({ ownerUpdated, templateId }));
                enqueueSnackbar(`Reassign template Successfully`, { variant: 'info' });
            } else {
                dispatch(slice.actions.createUpdateOwnerSuccess());
                enqueueSnackbar(`Reassign template failed`, { variant: 'error' });
            }
        } catch (ex) {
            console.log(ex);
            dispatch(slice.actions.createUpdateOwnerSuccess());
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar(`Reassign template failed`, { variant: 'error' });
            }
        }
    };
}

export function verifyEmail(enqueueSnackbar, email, templateId, isReassign, ownerId) {
    return async (dispatch) => {
        // Call API to get the list of dashboards for the logged in user - dashId, dashName
        try {
            if (isReassign) {
                dispatch(slice.actions.changeOwnerStart());
            }
            const response = await axiosServices.get(`/users?login=${email}`);
            if (response?.data) {
                const data = response.data;
                dispatch(slice.actions.validEmailAddress({ data, email }));

                if (isReassign) {
                    const payload = {
                        templateId,
                        users: [{ loginName: data?.loginName, userRole: 'Owner' }]
                    };
                    dispatch(changeTemplateOwner(enqueueSnackbar, payload, templateId));
                }
            }
        } catch (ex) {
            console.log(ex);
            dispatch(slice.actions.notValidEmailAddress(email));
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar('User is not valid', { variant: 'error' });
            }
        }
    };
}

export function getSavedTemplateList(enqueueSnackbar, offset, searchQuery, filterTagIds, isFavorite, isFromSearch, ownerIdFilter) {
    return async (dispatch) => {
        const payload = { DefaultTemplates: false, offset, limit: SIZE, searchQuery, filterTagIds, ownerIdFilter, isFavorite };
        dispatch(slice.actions.fetchSavedTemplateRequest(isFromSearch));
        // Call API to get the list of dashboards for the logged in user - dashId, dashName
        try {
            const response = await axiosReportService.get('/templates', { params: payload });
            if (response?.data?.success) {
                dispatch(slice.actions.getSavedListSuccess(response.data.data));
            } else {
                dispatch(slice.actions.getSavedListFailure(response?.data.error));
            }
        } catch (ex) {
            console.log(ex);
            dispatch(slice.actions.getSavedListFailure());
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar('Error in getting Saved templates', { variant: 'error' });
            }
        }
    };
}

export function saveAndUpddateTemplate(enqueueSnackbar, isUpdate, payload, templateId, isRename, templateType, notCloseModal) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createSaveTemplateStart());
            let response;
            if (isRename) {
                response = await axiosReportService.patch(`/templates/${templateId}/rename`, payload);
            } else if (isUpdate) {
                response = await axiosReportService.patch(`/templates/${templateId}/user`, payload);
            } else {
                response = await axiosReportService.post('/templates/user', payload);
            }

            if (response?.data?.success) {
                const data = response.data.data;
                dispatch(slice.actions.createSaveTemplateSuccess({ data, isRename, templateType, notCloseModal }));
                if (notCloseModal) {
                    enqueueSnackbar(`Tag added to the template Successfully`, { variant: 'info' });
                } else {
                    enqueueSnackbar(`Save template Successfully`, { variant: 'info' });
                }
            } else {
                dispatch(slice.actions.createSaveTemplateSuccess());
                enqueueSnackbar(`Save template failed`, { variant: 'error' });
            }
        } catch (ex) {
            console.log(ex);
            dispatch(slice.actions.createSaveTemplateSuccess());
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar(`${isUpdate ? 'Update' : 'Save'} template failed`, { variant: 'error' });
            }
        }
    };
}
export function deleteTemplateStandard(enqueueSnackbar, isUserConfirmed, templateIds, isFromShared) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateDeleteTemplateStart(true));
            let response;
            if (isFromShared) {
                response = await axiosReportService.delete(`/templates/${templateIds[0]}/share/self?isUserConfirmed=${isUserConfirmed}`);
            } else {
                response = await axiosReportService.delete(`/templates/user?isUserConfirmed=${isUserConfirmed}`, { data: templateIds });
            }
            dispatch(slice.actions.updateDeleteTemplateStart(false));
            dispatch(slice.actions.updateDeleteTemplateComplete(true));
            dispatch(slice.actions.createDeleteTemplate({ templateIds, isFromShared }));
            if (isUserConfirmed) {
                if (response?.data.success) {
                    enqueueSnackbar(`Delete template Successfully`, { variant: 'info' });
                } else {
                    enqueueSnackbar(`Delete template Failed`, { variant: 'error' });
                }
            }
        } catch (ex) {
            console.log(ex);
            dispatch(slice.actions.updateDeleteTemplateStart(false));
            if (ex?.response?.status === 400 && ex?.response?.data?.error?.includes('ErrorType')) {
                const error = JSON.parse(ex?.response?.data?.error);
                dispatch(slice.actions.updateDeleteConfirmationrequire({ isRequire: true, error }));
                console.log(error);
            } else if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar(`Delete template Failed`, { variant: 'error' });
            }
        }
    };
}

export function resetDeleteInProgress() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createResetTemplateLoaded());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function setDeleteConfirmationRequire() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.setDeleteConfirmationRequire());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function resetTemplateLoaded() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createResetTemplateLoaded());
        } catch (ex) {
            console.log(ex);
        }
    };
}
export function resetShareTemplateData() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createResetShareTemplateData());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function resetOwnerValidation() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.resetOwnerValidation());
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function resetDeleteTemplate() {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.updateDeleteTemplateStart(false));
            dispatch(slice.actions.updateDeleteTemplateComplete(false));
            dispatch(slice.actions.updateReassignComplete(false));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function updateExistingUserRole(user, selectedOption) {
    return async (dispatch) => {
        try {
            const email = user.shareeLoginName;
            dispatch(slice.actions.updateExistingUserRole({ email, selectedOption }));
        } catch (ex) {
            console.log(ex);
        }
    };
}

export function shareAndUpddateTemplate(enqueueSnackbar, isUpdate, payload, isRemove, templateType) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.createShareTemplateStart());
            let response;
            if (!isUpdate) {
                response = await axiosReportService.post(`/templates/${payload.templateId}/share`, payload);
            } else {
                response = await axiosReportService.patch(`/templates/${payload.templateId}/share`, payload);
            }
            if (response?.data?.success) {
                const templateId = payload.templateId;
                const owner = payload?.users?.map((user) => user.userRole === 'Owner');
                const isOwnerChange = owner[0];
                dispatch(slice.actions.createShareTemplateSuccess({ templateId, templateType, isOwnerChange }));
                if (isRemove) {
                    dispatch(slice.actions.setShareTemplateUsers(payload.users));
                    enqueueSnackbar(`Delete Access Successfully`, { variant: 'info' });
                } else if (isUpdate) {
                    enqueueSnackbar(`Update template Successfully`, { variant: 'info' });
                } else {
                    enqueueSnackbar(`Shared template successfully`, { variant: 'info' });
                }
            } else if (isRemove) {
                enqueueSnackbar(`Error in Deleting Access`, { variant: 'error' });
            } else if (isUpdate) {
                enqueueSnackbar(`Update template Failed`, { variant: 'error' });
            } else {
                enqueueSnackbar(`Share template failed`, { variant: 'error' });
            }
        } catch (ex) {
            console.log(ex);
            dispatch(slice.actions.createShareTemplateSuccess());
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else if (isRemove) {
                enqueueSnackbar(`Error in Deleting Access`, { variant: 'error' });
            } else if (isUpdate) {
                enqueueSnackbar(`Update template Failed`, { variant: 'error' });
            } else {
                enqueueSnackbar(`Share template failed`, { variant: 'error' });
            }
        }
    };
}

export function removeTemplateAccess(enqueueSnackbar, templateId, payloadArr) {
    return async (dispatch) => {
        try {
            payloadArr.forEach(async (payload) => {
                dispatch(slice.actions.createSaveTemplateStart());
                const response = await axiosReportService.delete(`/templates/${templateId}/share/user`, { data: payload });
                if (response?.data?.success) {
                    dispatch(slice.actions.updateShareTemplateUsers(payload.loginName));
                    dispatch(slice.actions.createDeleteAccessSuccess());
                    enqueueSnackbar(`Delete Access Successfully`, { variant: 'info' });
                } else {
                    enqueueSnackbar(`Error in Deleting Access`, { variant: 'error' });
                }
            });
        } catch (ex) {
            console.log(ex);
            dispatch(slice.actions.createDeleteAccessSuccess());
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar(`Error in Deleting Access`, { variant: 'error' });
            }
        }
    };
}

export function removeUserFromList(payload) {
    return (dispatch) => {
        dispatch(slice.actions.updateShareTemplateUsers(payload));
    };
}

export function getShareTemplateUsers(enqueueSnackbar, templateId, setOriginalDataMapCB) {
    return async (dispatch) => {
        try {
            const response = await axiosReportService.get(`/templates/${templateId}/share/users`);
            if (response?.data?.success) {
                setOriginalDataMapCB(response.data?.data);
                dispatch(slice.actions.setShareTemplateUsers(response.data?.data));
            } else {
                dispatch(slice.actions.setShareTemplateFailure());
                enqueueSnackbar('Error in getting Template users', { variant: 'error' });
            }
        } catch (ex) {
            console.log(ex);
            dispatch(slice.actions.setShareTemplateFailure());
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar('Error in getting Template users', { variant: 'error' });
            }
        }
    };
}

export function getSharedTemplateList(enqueueSnackbar, offset, searchQuery, filterTagIds, isFavorite, isFromSearch, ownerIdFilter) {
    return async (dispatch) => {
        const payload = { DefaultTemplates: false, offset, limit: SIZE, searchQuery, filterTagIds, isFavorite, ownerIdFilter };
        dispatch(slice.actions.fetchSharedTemplateRequest(isFromSearch));

        // Call API to get the list of dashboards for the logged in user - dashId, dashName
        try {
            const response = await axiosReportService.get(`/templates/share`, { params: payload });
            if (response?.data?.success) {
                dispatch(slice.actions.getSharedListSuccess(response.data?.data));
            } else {
                dispatch(slice.actions.getSharedListFailure());
                enqueueSnackbar('Error in getting Shared templates', { variant: 'error' });
            }
        } catch (ex) {
            console.log(ex);
            dispatch(slice.actions.getSharedListFailure());
            if (ex?.response?.status === 401) {
                enqueueSnackbar('Session expired, please login again', { variant: 'error' });
            } else {
                enqueueSnackbar('Error in getting Shared templates', { variant: 'error' });
            }
        }
    };
}

export function getOwnerList(userOwnerId) {
    return async (dispatch) => {
        try {
            const response = await axiosReportService.get(`/templates/templateOwnerList`);
            if (response?.data?.data) {
                const data = response.data.data.filter((d) => d.ownerID !== userOwnerId);
                dispatch(slice.actions.setOwnerList(data));
            }
        } catch (ex) {
            console.log(ex);
        }
    };
}
